import React, { useEffect } from "react";
import "./App.css";

import {
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import useMediaLayout from "use-media";
import styled from "styled-components";
import $ from "jquery";

import Home from "./components/pages/Home/Home";
import About from "./components/pages/About/About";
import Contact from "./components/pages/Contact/Contact";
import Press from "./components/pages/Press/Press";
import Portfolio from "./components/pages/Portfolio/Portfolio";
import VocalCoaching from "./components/pages/Vocal Coaching/VocalCoaching";

import Footer from "./components/common/Footer";
import { ScrollToTop } from "./components/common/Common";
import colours from "./components/common/colours";
import mediaQueries from "./components/common/mediaQueries";

const Header = styled.div`
  font-family: "Darker Grotesque", sans-serif;
  background: ${colours.lightTeal};
  display: flex;
  width: 100%;
  align-items: flex-end;
  position: fixed;
  height: 75px;
  box-shadow: 0 0 8px 0 #ccc;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
`;

const DesktopNav = styled.nav`
  ul {
    list-style-type: none;
    margin: 0 0 0 auto;
    padding: 16px 16px 6px 16px;
    display: flex;
    overflow: hidden;
  }
  li {
    transition-duration: 400ms;
    &:hover {
      opacity: 1 !important;
      // border-bottom: solid 3px #556970;
      transition-duration: 400ms;
    }
  }
`;

const MobileNav = styled.nav`
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 150%;
  padding-top: 150px;
  width: 100%;
  display: table;
  text-align: center;
  opacity: 0;
  transition: all 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: scale(0);
  &.act {
    opacity: 1;
    transform: scale(1);
    ul li {
      opacity: 1;
      transform: translateX(0);
    }
  }
  ul {
    padding-left: 0;
    display: table-cell;
    list-style-type: none;
  }
  li {
    padding: 8px 0;
    transition: all 400ms 510ms;
    opacity: 0;
    &:nth-child(odd) {
      transform: translateX(30%);
    }
    &:nth-child(even) {
      transform: translateX(-30%);
    }
    &:last-child {
      transform: none;
    }
  }
  a {
    color: #19b698;
    display: inline-block;
    font-size: 18px;
  }
`;

const NavTitle = styled.div`
  font-size: 48px;
  padding: 0 12px;
  height: 100%;

  a {
    text-decoration: none;
    color: ${colours.charcoalGrey};
    &:hover {
      text-decoration: none;
      color: ${colours.charcoalGrey};
    }
    &:visted {
      text-decoration: none;
      color: ${colours.charcoalGrey};
    }

    &:active {
      text-decoration: none;
      color: ${colours.charcoalGrey};
    }
  }

  @media screen and (min-width: ${mediaQueries.large}) {
    font-size: 56px;
    padding: 0 20px;
    height: auto;
  }
`;

const MenuBtn = styled.a`
  height: 30px;
  width: 30px;

  position: relative;
  margin-right: 12px;
  z-index: 101;

  &.act {
    span {
      height: 0;
      &:after,
      &:before {
        background-color: ${colours.charcoalGrey};
        top: 1px;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
  }
`;

const MenuBtnIcon = styled.span`
  background-color: ${colours.charcoalGrey};
  border-radius: 1px;
  height: 2px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1px 0 0 -15px;
  transition: height 100ms;
  &:after,
  &:before {
    content: "";
    background-color: ${colours.charcoalGrey};
    border-radius: 1px;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    transition: all 200ms;
  }
  &:after {
    top: -7px;
  }
  &:before {
    bottom: -7px;
  }
`;

const ListItem = styled.li`
  float: left;
  opacity: ${(props) => (props.location === useLocation().pathname ? 1 : 0.5)};
  border-bottom: solid 3px #556970;
  a {
    font-size: 36px;
    text-decoration: none;
    padding: 16px;
    color: white;
  }
`;

const MobileNavItem = styled.li`
  a {
    color: ${colours.charcoalGrey};
    text-decoration: none;
    font-size: 32px;
  }
`;

function App() {
  const isDesktop = useMediaLayout({ minWidth: mediaQueries.xLarge });
  useEffect(() => {
    $(document).ready(function () {
      // menu click event
      $(".menuBtn").click(function () {
        $(this).toggleClass("act");
        if ($(this).hasClass("act")) {
          $(".mainMenu").addClass("act");
        } else {
          $(".mainMenu").removeClass("act");
        }
      });
      $(".mobile-nav-item").click(function () {
        $(".menuBtn").toggleClass("act");
        if ($(".menuBtn").hasClass("act")) {
          $(".mainMenu").addClass("act");
        } else {
          $(".mainMenu").removeClass("act");
        }
      });
    });
  }, []);
  return (
    <Router>
      <div>
        <Header>
          <NavTitle>
            <Link to="/">Eric Elliot Lee</Link>
          </NavTitle>
          {isDesktop ? (
            <DesktopNav>
              <ul>
                <ListItem location="/">
                  <Link to="/">Home</Link>
                </ListItem>
                <ListItem location="/about">
                  <Link to="/about">About Me</Link>
                </ListItem>
                <ListItem location="/coaching">
                  <Link to="/coaching">Vocal Coaching</Link>
                </ListItem>
                <ListItem location="/portfolio">
                  <Link to="/portfolio">Portfolio</Link>
                </ListItem>
                <ListItem location="/press">
                  <Link to="/press">Press</Link>
                </ListItem>
                <ListItem location="/contact">
                  <Link to="/contact">Contact</Link>
                </ListItem>
              </ul>
            </DesktopNav>
          ) : (
            <>
              <MenuBtn className="menuBtn" href="#">
                <MenuBtnIcon className="menuBtnIcon" />
              </MenuBtn>
              <MobileNav className="mainMenu">
                <ul>
                  <MobileNavItem className="mobile-nav-item">
                    <Link to="/">Home</Link>
                  </MobileNavItem>
                  <MobileNavItem className="mobile-nav-item">
                    <Link to="/about">About Me</Link>
                  </MobileNavItem>
                  <MobileNavItem className="mobile-nav-item">
                    <Link to="/coaching">Vocal Coaching</Link>
                  </MobileNavItem>
                  <MobileNavItem className="mobile-nav-item">
                    <Link to="/portfolio">Portfolio</Link>
                  </MobileNavItem>
                  <MobileNavItem className="mobile-nav-item">
                    <Link to="/press">Press</Link>
                  </MobileNavItem>
                  <MobileNavItem className="mobile-nav-item">
                    <Link to="/contact">Contact</Link>
                  </MobileNavItem>
                </ul>
              </MobileNav>
            </>
          )}
        </Header>
        <ScrollToTop />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/coaching">
            <VocalCoaching />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/press">
            <Press />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
