import styled from "styled-components";

import mediaQueries from "../../common/mediaQueries";

import {
  NavSpacer,
  PageContainer,
  imageWithCaption,
} from "../../common/Common";
import colours from "../../common/colours";
import aboutImage from "../../../assets/cat.jpg";

const AboutContainer = styled.div`
  padding: 16px;
`;

const AboutBodyContainer = styled.div`
  @media screen and (min-width: ${mediaQueries.large}) {
    display: flex;
    justify-content: space-evenly;
    padding: 0 0 250px;
  }
`;

const HeaderUnderline = styled.span`
  background: ${colours.lightPurple};
  height: 10px;
  position: absolute;
  bottom: 10%;
  z-index: -1;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 30%;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 90%;
  }

  animation: fadeInAbout ease 1200ms;
  -webkit-animation: fadeInAbout ease 1200ms;

  @keyframes fadeInAbout {
    0% {
      width: 0%;
    }
    100% {
      @media screen and (min-width: ${mediaQueries.large}) {
        width: 30%;
      }
      @media screen and (max-width: ${mediaQueries.large}) {
        width: 90%;
      }
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 32px;

  @media screen and (min-width: ${mediaQueries.large}) {
    margin: 24px 0 50px 10%;
  }
`;

const AboutHeader = styled.h1`
  color: black;
  margin-top: 0 !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: revert;
  }
`;

const AboutImageContainer = styled.div`
  img {
    object-position: 0% 40%;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
  }
`;

const AboutText = styled.p`
  // padding: 16px;
`;

const TextContainer = styled.div`
  padding: 16px;
  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
  }
`;

const StyledLink = styled.a`
  font-weight: bold;
  opacity: 1;
  color: black;
  &:hover {
    opacity: 0.6;
  }

  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: small;
  }
`;

function About() {
  return (
    <PageContainer>
      <NavSpacer />
      <AboutContainer>
        <HeaderContainer>
          <AboutHeader>About Me</AboutHeader>
          <HeaderUnderline />
        </HeaderContainer>
        <AboutBodyContainer>
          <AboutImageContainer>
            {imageWithCaption(
              aboutImage,
              "Eric’s enthusiasm and energetic personality inspire other students",
              "Dr. Irene Feher"
            )}
          </AboutImageContainer>
          <TextContainer>
            <AboutText>
              Eric Elliot Lee is a Montreal-based Musician and Theatre
              Practitioner originally hailing from Waterloo, Ontario. Eric spent
              his childhood in various musical ensembles including KW Glee, the
              Grand Philharmonic Youth Choir, the KW Symphony Youth Concert
              Band, and more. Eric started playing piano at the Beckett School
              of Music at the age of 5, and soon after started learning every
              instrument he could get his hands on. He has had the privilege of
              studying under many incredible teachers/mentors including Peter de
              Sousa (Piano), Dave Wiffen (Saxophone), Danielle Beck (Saxophone),
              Larry Moser (Saxophone/Clarinet), and Julio Lopez-Martin (Guitar).
              At the age of 14, Eric performed in his first Amateur musical as
              Benny in a production of “RENT” with the Singers’ Theatre under
              the direction of Gord Davis and music direction of Amanda Brunk, a
              company that he would continue to perform with three more times.
              Eric would then go on to work closely with Amanda Brunk as the
              bass section leader of the international award winning Grand
              Philharmonic Youth Choir.
              <br />
              <br />
              Eric moved to Montreal in 2013 to pursue a Bachelor of Arts degree
              in Economics with a minor in Music at McGill University. While
              studying at McGill, Eric became heavily involved with the A
              Cappella Music community as VP Internal, Music Director, and
              President of Chromatones A Cappella. During his final year, Eric
              rediscovered his love for the theatre when he performed in a
              production of “Heathers: The Musical” produced by the Arts
              Undergraduate Theatre Society, and as an original cast member of
              the original musical, “The Original,” put on as part of the McGill
              Drama Festival. After graduating from McGill and spending a couple
              years working a day job, Eric realized that Music and Theatre are
              his true callings, and decided to pursue a Bachelor of Fine Arts
              degree at Concordia University pursuing a specialization in Music
              composition. Eric completed his BFA in 2021 and studied voice
              performance with Dr. Irene Feher, Music Composition with Dr.
              Georges Dimitrov and Dr. Sandeep Bhagwati, and Music Theory with
              Dr. Yaron Ross & Dr. Christine Beckett.
              <br />
              <br />
              Eric is an avid member of the Canadian Musical Theatre Writers’
              Collective, studying the art of Musical Writing under the
              mentorship of Jonathan Monro in the inaugural Montreal workshop
              and the Advanced Writers’ workshop. He is also a contributor to
              <StyledLink
                href="https://www.ContemporaryMusicalTheatre.com"
                target="_blank"
              >
                {` `}ContemporaryMusicalTheatre.com
              </StyledLink>
              , a New York based website that showcases new Musical Theatre
              pieces and helps advantage emerging artists. When he’s not
              writing, Eric works various gigs around the Montreal theatre
              community, primarily as a music director. He is currently one of
              the many wonderful music directors of the academy at the Segal
              Centre for the Performing Arts, the most recent music director for
              the Côte St-Luc Dramatic Society’s “Senior Summer Works,” and has
              worked as the assistant music director to Nick Burgess of the Dora
              Wasserman’s Yiddish Theatre. He also has a deep love for the
              Quebec theatre community and has spent some time working in
              advocacy as part of the staff for the Quebec Drama Federation.
              Aside from Music and Theatre, Eric is a huge fan of Magic: The
              Gathering, and loves cooking and spending time in nature.
            </AboutText>
          </TextContainer>
        </AboutBodyContainer>
      </AboutContainer>
    </PageContainer>
  );
}

export default About;
