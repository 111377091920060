import styled from "styled-components";
import PropTypes from "prop-types";
import useMediaLayout from "use-media";
import { useEffect } from "react";
import $ from "jquery";

import rightArrow from "../../../assets/right-arrow.png";

import mediaQueries from "../../common/mediaQueries";

const ReadMoreArrow = styled.img`
  height: 20px;
  margin: 0 8px 0 16px;

  -webkit-animation: bounceSide 3s infinite 3s;
  animation: bounceSide 3s infinite 3s;

  @keyframes bounceSide {
    10%,
    30%,
    50% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    20% {
      -webkit-transform: translateX(8px);
      -ms-transform: translateX(8px);
      transform: translateX(8px);
    }
    40% {
      -webkit-transform: translateX(8px);
      -ms-transform: translateX(8px);
      transform: translateX(8px);
    }
  }
`;

const PressUnitContainer = styled.div`
  color: black;

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: inherit;
  }

  @media screen and (min-width: ${mediaQueries.large}) {
    padding: 24px;
    width: 75%;
    display: flex;
    align-items: flex-end;
  }
`;

const PressImg = styled.img`
  object-fit: cover;
  width: 100%;
  opacity: 1;
  transition-duration: 300ms;
  @media screen and (min-width: ${mediaQueries.large}) {
    height: 400px;
    width: 450px;
  }
`;

const PressSource = styled.h1`
  font-size: 24px !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    font-size: 36px !important;
  }
`;

const Date = styled.h2`
  font-size: 20px;
  margin-top: 0;
`;

const Blurb = styled.p``;

const ReadMoreContainer = styled.a`
  background: transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 48px;
  color: black;

  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0;

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: black;
  }

  position: relative;
  background-color: transparent;
  cursor: pointer;

  @media screen and (min-width: ${mediaQueries.large}) {
    justify-content: left;
    margin-bottom: 8px;
  }
`;
const ReadMoreText = styled.h2`
  width: fit-content;
  margin: 0;
  font-size: 18px;
  font-family: "Alata", sans-serif !important;
`;

const PressBody = styled.div`
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-left: 32px;
    // width: 500px;
`;

function PressUnit({
  imageSrc,
  pressSrc,
  date,
  blurb,
  btnColour,
  pressLink,
  className,
  index,
}) {
  const bodyId = `body-${index}`;
  const isDesktop = useMediaLayout({ minWidth: mediaQueries.large });
  useEffect(() => {
    if (isDesktop) {
      $(document).scroll(function () {
        var docScroll = $(document).scrollTop(),
          offset = $(`#${bodyId}`).offset().top;
        // console.log("offset", offset);
        // console.log("doc", docScroll);
        // console.log("height", $(`#${bodyId}`).outerHeight());
        var opacity;
        if (
          docScroll >= offset - 300 &&
          docScroll <= offset + $(`#${bodyId}`).outerHeight() - 300
        ) {
          opacity = "1";
        } else {
          opacity = "0.5";
        }
        $(`#${bodyId}`).stop().animate({ opacity: opacity }, 400);
      });
    }
  });

  return (
    <PressUnitContainer className={className} id={bodyId}>
      <a href={pressLink} target="_blank">
        <PressImg src={imageSrc} />
      </a>
      <PressBody>
        <PressSource>{pressSrc}</PressSource>
        <Date>{date}</Date>
        <Blurb>{blurb}</Blurb>
        <ReadMoreContainer colour={btnColour} href={pressLink} target="_blank">
          <ReadMoreText>Read More</ReadMoreText>
          <ReadMoreArrow src={rightArrow} />
        </ReadMoreContainer>
      </PressBody>
    </PressUnitContainer>
  );
}

PressUnit.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  pressSrc: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  btnColour: PropTypes.string.isRequired,
  pressLink: PropTypes.string.isRequired,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export default PressUnit;

// arrow link: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
