import { useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";

import { Link } from "react-router-dom";
import { useMediaLayout } from "use-media";

import {
  PageContainer,
  NavSpacer,
  imageWithCaption,
} from "../../common/Common";
import colours from "../../common/colours";

import mediaQueries from "../../common/mediaQueries";
import introImage from "../../../assets/wand.jpg";
import heroImage from "../../../assets/elbow.jpg";
import rightArrow from "../../../assets/right-arrow.png";

const Hero = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const HeroImage = styled.img`
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: 0 48%;
  position: fixed;
  z-index: -1;
`;

const HeroText = styled.div`
  position: absolute;
  bottom: 30%;
  display: flex;
  @media screen and (min-width: ${mediaQueries.large}) {
    bottom: 75px;
  }
`;

const HeroTextItem = styled.h2`
  color: white;
  font-family: "Darker Grotesque";
  font-size: 20px;
  opacity: 1;
  animation: opac ${(props) => props.delay}s;
  -webkit-animation: opac ${(props) => props.delay}s;

  @media screen and (min-width: ${mediaQueries.large}) {
    font-size: 32px;
  }

  @keyframes opac {
    0% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes opac {
    0% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes opac {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const DownButton = styled.a`
  opacity: 1;
  // -webkit-transition: all 0.5s ease-in 3s;
  // transition: all 0.5s ease-in 3s;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -16px;
  display: block;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  background-size: 14px auto;
  border-radius: 50%;
  // z-index: 2;
  -webkit-animation: bounceUp 2s infinite 2s;
  animation: bounceUp 2s infinite 2s;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  &:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
  }

  @keyframes bounceUp {
    0%,
    100%,
    20%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    60% {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
`;

const IntroContainer = styled.div`
  min-height: fit-content;
  background: white;
  padding-bottom: 100px;

  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: 100px;
  }
`;

const Intro = styled.div`
  @media screen and (min-width: ${mediaQueries.large}) {
    padding-top: 0;
    width: 100%;
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
  }
`;

const IntroImageContainer = styled.div`
  padding: 16px 16px 0;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
    padding: 0;
  }
`;

const IntroBody = styled.div`
  padding: 0 16px 16px;
  height: fit-content;
  background: white;
  text-align: center;
  text-align: -webkit-center;
  z-index: 1;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 35%;
    box-shadow: 5px 5px 5px 5px #888888;
    text-align: none;
  }
`;
const IntroHeaderContainer = styled.div`
  height: fit-content;
  width: 100%;
  margin-bottom: 50px;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: 50px;
  }
`;

const IntroHeader = styled.h1`
  text-align: center;
  margin-bottom: 0;
  margin-top: 24px;

  @media screen and (min-width: ${mediaQueries.large}) {
    text-align: right;
    margin-top: 0;
  }
`;
const IntroUnderline = styled.span`
  background: ${colours.brightMustard};
  display: flex;
  height: 10px;
  margin-top: -25px;
  @media screen and (min-width: ${mediaQueries.large}) {
    width: 0%;
    float: right;
    display: inline-block;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 18rem;
  }
`;

const IntroText = styled.p`
  text-align: right;
`;

const IntroButtonLink = styled(Link)`
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  margin: 32px 12px 48px;
  color: black;

  font-size: 18px;
  font-family: "Alata", sans-serif !important;
  font-weight: bold;

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: black;
  }

  position: relative;
  cursor: pointer;

  @media screen and (min-width: ${mediaQueries.large}) {
    float: right;
    margin: 32px 0;
    padding: 0;
  }
`;

const ReadMoreArrow = styled.img`
  height: 20px;
  margin: 0 8px 0 16px;

  -webkit-animation: bounceSide 3s infinite 3s;
  animation: bounceSide 3s infinite 3s;

  @keyframes bounceSide {
    10%,
    30%,
    50% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    20% {
      -webkit-transform: translateX(8px);
      -ms-transform: translateX(8px);
      transform: translateX(8px);
    }
    40% {
      -webkit-transform: translateX(8px);
      -ms-transform: translateX(8px);
      transform: translateX(8px);
    }
  }
`;

// const CalContainer = styled.div`
//   background: white;
//   padding: 64px 32px 100px;
// `;

// const StylediFrame = styled.iframe`
//   .scrollbox {
//     overflow: hidden !important;
//   }

//   .view-container-border {
//     background: black;
//   }

//   tbody {
//     display: none !important;
//   }
// `;

// const CalHeading = styled.h1`
//   font-size: 32px !important;
//   text-align: center;
// `;

function Home() {
  const isDesktop = useMediaLayout({ minWidth: mediaQueries.large });
  useEffect(() => {
    if ($(".intro-top").offset()) {
      $(".scroll-down").click(function () {
        $("html,body").animate(
          {
            scrollTop: $(".intro-top").offset().top,
          },
          "slow"
        );
      });
    }

    if (isDesktop && $(".intro-top").offset()) {
      $(document).scroll(function () {
        var docScroll = $(document).scrollTop(),
          offset = $(".intro-top").offset().top;
        var width;
        if (docScroll >= offset - 300) {
          width = "18rem";
        } else {
          width = "0%";
        }
        $("#header-id").stop().animate({ width: width }, 400);
      });
    }
  });

  return (
    <div id="container">
      <Hero>
        <HeroImage src={heroImage} />
        {/* <HeroList>
          <li>Singer</li>
          <li>Actor</li>
          <li>Composer</li>
          <li>Music Director</li>
        </HeroList> */}
        <HeroText>
          <HeroTextItem delay="1">Singer |&nbsp;</HeroTextItem>
          <HeroTextItem delay="1.5">Actor |&nbsp;</HeroTextItem>
          <HeroTextItem delay="2.25">Composer |&nbsp;</HeroTextItem>
          <HeroTextItem delay="3">Music Director</HeroTextItem>
        </HeroText>
        <DownButton href="#intro" className="scroll-down" address="true" />
      </Hero>
      <PageContainer>
        <IntroContainer className="intro-top">
          <NavSpacer />
          <Intro id="intro">
            <IntroImageContainer>
              {imageWithCaption(
                introImage,
                "One of Quebec musical theatre’s most promising emerging artists",
                "The Quebec Drama Federation"
              )}
            </IntroImageContainer>
            <IntroBody>
              <IntroHeaderContainer>
                <IntroHeader>Welcome!</IntroHeader>
                <IntroUnderline id="header-id" />
              </IntroHeaderContainer>
              <IntroText>
                Looks like you’ve found your way to my website, thanks for
                stopping by! Here, you’ll find information about how to get in
                contact with me, what projects I have in the pipeline, and you
                can check out some of my published work. Hope you enjoy your
                time here!
              </IntroText>
              <IntroButtonLink to="/about">
                More About Me
                <ReadMoreArrow src={rightArrow} />
              </IntroButtonLink>
            </IntroBody>
          </Intro>
        </IntroContainer>
        {/* <CalContainer>
          <CalHeading>Upcoming Events</CalHeading>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FToronto&amp;src=dDdzNjZ0amNyY2p2NDUwc2RtOXV0Y2ZuMmNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%237986CB&amp;mode=AGENDA&amp;showTitle=0&amp;showNav=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0"
            style={{ border: 0 }}
            width="100%"
            height="300"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </CalContainer> */}
      </PageContainer>
    </div>
  );
}

export default Home;
