import styled from "styled-components";

import mediaQueries from "../../common/mediaQueries";

import {
  NavSpacer,
  PageContainer,
  imageWithCaption,
} from "../../common/Common";
import colours from "../../common/colours";
import aboutImage from "../../../assets/studio.png";

const AboutContainer = styled.div`
  padding: 16px;
`;

const AboutBodyContainer = styled.div`
  @media screen and (min-width: ${mediaQueries.large}) {
    display: flex;
    justify-content: space-evenly;
    padding: 0 0 250px;
  }
`;

const HeaderUnderline = styled.span`
  background: ${colours.paleGreen};
  height: 10px;
  position: absolute;
  bottom: 10%;
  z-index: -1;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 90%;
  }

  animation: fadeInAbout ease 1200ms;
  -webkit-animation: fadeInAbout ease 1200ms;

  @keyframes fadeInAbout {
    0% {
      width: 0%;
    }
    100% {
      @media screen and (min-width: ${mediaQueries.large}) {
        width: 30%;
      }
      @media screen and (max-width: ${mediaQueries.large}) {
        width: 90%;
      }
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 32px;

  @media screen and (min-width: ${mediaQueries.large}) {
    margin: 24px 0 50px 10%;
  }
`;

const AboutHeader = styled.h1`
  color: black;
  margin-top: 0 !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: revert;
  }
`;

const AboutImageContainer = styled.div`
  img {
    object-position: 0% 40%;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
  }
`;

const AboutText = styled.p`
  // padding: 16px;
`;

const TextContainer = styled.div`
  padding: 16px;
  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
  }
`;

const ContactLink = styled.a`
  opacity: 1;
  color: black;
  font-weight: bold;
  &:hover {
    opacity: 0.6;
  }
`;

function VocalCoaching() {
  return (
    <PageContainer>
      <NavSpacer />
      <AboutContainer>
        <HeaderContainer>
          <AboutHeader>Vocal Coaching</AboutHeader>
          <HeaderUnderline />
        </HeaderContainer>
        <AboutBodyContainer>
          <AboutImageContainer>
            {imageWithCaption(
              aboutImage,
              "Eric’s enthusiasm and energetic personality inspire other students",
              "Dr. Irene Feher"
            )}
          </AboutImageContainer>
          <TextContainer>
            <AboutText>
              Have you wanted to brush up on your Music Theory? Need to learn
              how to read sheet music? Looking for an accompaniment track for an
              audition or cabaret performance? I’m happy to say that I am now
              offering my services as a vocal coach.
              <br />
              <br />I have studied with Dr. Irene Feher of Concordia University
              in both Classical and Contemporary voice stylings, and have been
              singing in almost every context since I was 11. So, if you’re
              looking for someone to help guide you through your singing journey
              or dig a little deeper into how to make a song soar, send me an
              e-mail at{" "}
              <ContactLink href="mailto:ericelliotlee@gmail.com">
                ericelliotlee@gmail.com
              </ContactLink>
              . My rates and schedule are flexible.
            </AboutText>
          </TextContainer>
        </AboutBodyContainer>
      </AboutContainer>
    </PageContainer>
  );
}

export default VocalCoaching;
