import styled from "styled-components";

import mediaQueries from "../../common/mediaQueries";

import PressUnit from "./PressUnit";

import { NavSpacer, PageContainer } from "../../common/Common";
import colours from "../../common/colours";
import cheeseImg from "../../../assets/cheese.jpg";
import friendsImg from "../../../assets/friends.jpeg";
import stageImg from "../../../assets/eric.jpeg";

const PressContainer = styled.div`
  padding: 16px;
`;

const HeaderUnderline = styled.span`
  background: ${colours.paleRed};
  height: 10px;
  position: absolute;
  bottom: 10%;
  z-index: -1;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 30%;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 90%;
  }

  animation: fadeInPress ease 1200ms;
  -webkit-animation: fadeInPress ease 1200ms;

  @keyframes fadeInPress {
    0% {
      width: 0%;
    }
    100% {
      @media screen and (min-width: ${mediaQueries.large}) {
        width: 30%;
      }
      @media screen and (max-width: ${mediaQueries.large}) {
        width: 90%;
      }
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 32px;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin: 24px 0 0 10%;
  }
`;

const Header = styled.h1`
  color: black;
  margin-top: 0 !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: revert;
  }
`;

const PressUnits = styled.div`
  // background: red;

  @media screen and (min-width: ${mediaQueries.large}) {
    padding: 16px;
    margin: auto;
    justify-content: space-around;
  }
`;

const PressUnitStyled = styled(PressUnit)`
  margin: auto;
  img {
    object-position: top;
  }
`;

function Press() {
  return (
    <PageContainer>
      <NavSpacer />
      <PressContainer>
        <HeaderContainer>
          <Header>Press</Header>
          <HeaderUnderline />
        </HeaderContainer>
        <PressUnits>
          <PressUnitStyled
            imageSrc={cheeseImg}
            pressSrc={"Quebec Drama Federation"}
            date={"November 9th, 2020"}
            blurb={
              "From playing piano at the age of 5 to becoming one of Quebec musical theatre’s most promising emerging artists, Eric’s journey in the performing arts has been one of connecting to the inner voice to unleash the stories within."
            }
            btnColour={colours.lightGrey}
            pressLink={
              "https://www.quebecdrama.org/blog/eric-lee?fbclid=IwAR1U9zYJjbS93vBOJJiRyzpKORT22A7lV6S0WBeTejE3Eurf8Ieq60j8Txc"
            }
            index={1}
          />
          <PressUnitStyled
            imageSrc={friendsImg}
            pressSrc={"Montreal Theatre Hub"}
            date={"September 14th, 2019"}
            blurb={
              "The new Montreal indie theatre company leaves its mark with a charming autobiographical piece on the struggles of being an artist."
            }
            btnColour={colours.lightGrey}
            pressLink={
              "https://montrealtheatrehub.com/2019/09/14/meta-musical-title-of-show-a-virtuous-debut-for-home-theatre-productions/"
            }
            index={2}
          />
          <PressUnitStyled
            imageSrc={stageImg}
            pressSrc={"Montreal Theatre Hub"}
            date={"May 29th, 2021"}
            blurb={
              "In this Snapshot, we feature ERIC ELLIOT LEE, one of the composer-lyricists of HOW THE FABLES HAVE TURNED, a digital song cycle presented by Home Theatre Productions from June 1-20 on the FringeTV platform."
            }
            btnColour={colours.lightGrey}
            pressLink={
              "https://montrealtheatrehub.com/2021/05/29/fringe-artist-snapshots-eric-elliot-lee/?fbclid=IwAR3fPDpGgO8HVxVs3yTdmILfpEzH6i_JdCo9iKf5_gmjJTmi0_wMzqkpnvc"
            }
            index={3}
          />
        </PressUnits>
      </PressContainer>
    </PageContainer>
  );
}

export default Press;
