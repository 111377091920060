import styled from "styled-components";
import PropTypes from "prop-types";

import mediaQueries from "../common/mediaQueries";

// import insta from "../../assets/instagram.png";
import fb from "../../assets/facebook.png";
import youtube from "../../assets/youtube.png";

const LinkContainer = styled.div`
  display: flex;
  width: ${(props) => props.mobileWidth};
  justify-content: space-evenly;
  filter: invert(100%);
  @media screen and (min-width: ${mediaQueries.large}) {
    width: ${(props) => props.desktopWidth};
  }
`;

const SocialLink = styled.a`
  //   margin: 0 32px;
  &:hover {
    cursor: pointer;
  }
`;

const SocialImg = styled.img`
  width: ${(props) => props.mobileSize}px;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: ${(props) => props.desktopSize}px;
  }

  transition-duration: 2s;
  &:hover {
    -webkit-animation: bounce 750ms;
    animation: bounce 750ms;
  }
  @keyframes bounce {
    100%,
    50%,
    80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    60% {
      -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }
`;

function Socials({ mobileSize, desktopSize, desktopWidth, mobileWidth }) {
  return (
    <LinkContainer
      desktopWidth={desktopWidth}
      mobileWidth={mobileWidth}
      className="link-container"
    >
      <SocialLink
        target="_blank"
        href="https://www.facebook.com/ericelliotlee/?view_public_for=371813850128024"
      >
        <SocialImg src={fb} mobileSize={mobileSize} desktopSize={desktopSize} />
      </SocialLink>
      <SocialLink
        target="_blank"
        href="https://www.instagram.com/ericelliotlee/?hl=en"
      >
        {/* <SocialImg
          src={insta}
          mobileSize={mobileSize}
          desktopSize={desktopSize}
        /> */}
      </SocialLink>
      <SocialLink
        target="_blank"
        href="https://www.youtube.com/channel/UCA38mzXV-xkJ6QUHvq5KYOg?fbclid=IwAR2in2fCAc60SvP032ZtZzaf-ROlPLqnTgFpXkQUAztRiwajxZ4F8RXWBEU"
      >
        <SocialImg
          src={youtube}
          mobileSize={mobileSize}
          desktopSize={desktopSize}
        />
      </SocialLink>
    </LinkContainer>
  );
}

Socials.propTypes = {
  desktopSize: PropTypes.string,
  mobileSize: PropTypes.string,
  desktopWidth: PropTypes.string,
  mobileWidth: PropTypes.string,
};

export default Socials;

/* 

CREDITS

fb: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
insta: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
youtube: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

*/
