import styled from "styled-components";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import mediaQueries from "./mediaQueries";

function ScrollTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

export const ScrollToTop = withRouter(ScrollTop);

export const PageContainer = styled.div`
  //   padding: 16px;
  //   width: calc(100vw - (100vw - 100%));
  p {
    // font-family: "Open Sans", sans-serif;
    font-family: "Alata", sans-serif;
    text-align: justify;
  }

  a {
    font-family: "Alata", sans-serif;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:visted {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }

  h1 {
    // font-family: "Merriweather", serif;
    font-family: "DM Serif Display", serif;
    font-size: 4rem;
  }

  h2 {
    font-family: "Darker Grotesque";
  }
`;

// TODO: combine with PageContainer
export const NavSpacer = styled.div`
  width: 100%;
  height: 75px;
  // @media screen and (min-width: ${mediaQueries.large}) {
  //   height: 75px;
  // }
`;

export const temp = styled.div``;

const IntroImage = styled.img`
  width: 100%;
  height: 360px;
  object-fit: cover;
  @media screen and (min-width: ${mediaQueries.large}) {
    height: auto;
  }
`;

const IntroImageCaption = styled.div`
  @media screen and (max-width: ${mediaQueries.large}) {
    p {
      margin: 4px;
      font-size: 14px;
    }
  }
`;
const Caption = styled.p`
  text-align: center !important;
  font-style: italic;
`;
const CaptionAuthor = styled.p`
  text-align: center !important;
`;

export function imageWithCaption(src, caption, author) {
  return (
    <>
      <IntroImage src={src} />
      <IntroImageCaption>
        {caption && author ? (
          <>
            <Caption>“{caption}”</Caption>
            <CaptionAuthor>– {author}</CaptionAuthor>
          </>
        ) : (
          <></>
        )}
      </IntroImageCaption>
    </>
  );
}
