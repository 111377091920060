import styled from "styled-components";
import PropTypes from "prop-types";
import useMediaLayout from "use-media";
import { useEffect } from "react";
import $ from "jquery";
import ReactPlayer from "react-player";

import rightArrow from "../../../assets/right-arrow.png";

import mediaQueries from "../../common/mediaQueries";

const ReadMoreArrow = styled.img`
  height: 20px;
  margin: 0 8px 0 16px;

  -webkit-animation: bounceSide 3s infinite 3s;
  animation: bounceSide 3s infinite 3s;

  @keyframes bounceSide {
    10%,
    30%,
    50% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    20% {
      -webkit-transform: translateX(8px);
      -ms-transform: translateX(8px);
      transform: translateX(8px);
    }
    40% {
      -webkit-transform: translateX(8px);
      -ms-transform: translateX(8px);
      transform: translateX(8px);
    }
  }
`;

const ImgProjectContainer = styled.div`
  color: black;

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: inherit;
  }

  @media screen and (min-width: ${mediaQueries.large}) {
    padding: 24px;
    // width: 75%;
    display: flex;
    align-items: flex-start;
  }
  padding: 16px 16px 0;
`;

const YoutubeProjectContainer = styled.div`
  color: black;

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: inherit;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    text-align: center;
    text-align-last: -webkit-center;
    text-align-last: center;
    margin-bottom: 48px;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    padding: 24px;
    // width: 75%;
    display: flex;
    align-items: flex-start;
    position: relative;
  }
`;

const ProjectImg = styled.img`
  object-fit: cover;
  width: 100%;
  opacity: 1;
  transition-duration: 300ms;
  @media screen and (min-width: ${mediaQueries.large}) {
    height: 400px;
    width: 450px;
  }
`;

const ImgProjectTitle = styled.h1`
  font-size: 24px !important;
  margin-bottom: 0;
`;

const YoutubeProjectTitle = styled.h1`
  font-size: 20px !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    position: absolute;
    font-size: 24px !important;
    left: 500px;
  }
`;

const Date = styled.h2`
  font-size: 20px;
  margin-top: 0;
`;

const ReadMoreContainer = styled.a`
  background: transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 48px;
  color: black;

  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0;

  &:active,
  &:visited,
  &:focus,
  &:hover {
    color: black;
  }

  position: relative;
  background-color: transparent;
  cursor: pointer;

  @media screen and (min-width: ${mediaQueries.large}) {
    justify-content: left;
    margin-bottom: 8px;
  }
`;
const ReadMoreText = styled.h2`
  width: fit-content;
  margin: 0;
  font-size: 16px;
  font-family: "Alata", sans-serif !important;
`;

const ProjectBody = styled.div`
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-left: 32px;
  }
  p {
    font-size: 14px;
  }
`;

function ProjectUnit({
  type,
  title,
  imageSrc,
  date,
  blurb,
  link,
  className,
  index,
}) {
  const bodyId = `body-${index}`;
  const isDesktop = useMediaLayout({ minWidth: mediaQueries.large });
  useEffect(() => {
    if (isDesktop) {
      $(document).scroll(function () {
        var docScroll = $(document).scrollTop(),
          offset = $(`#${bodyId}`).offset().top;
        var opacity;
        if (
          docScroll >= offset - 350 &&
          docScroll <= offset + $(`#${bodyId}`).outerHeight() - 350
        ) {
          opacity = "1";
        } else {
          opacity = "0.5";
        }
        $(`#${bodyId}`).stop().animate({ opacity: opacity }, 300);
      });
    }
  });

  return (
    <>
      {type === "img" && (
        <ImgProjectContainer className={className} id={bodyId}>
          <a href={link} target="_blank">
            <ProjectImg src={imageSrc} />
          </a>
          <ProjectBody>
            <ImgProjectTitle>{title}</ImgProjectTitle>
            <Date>{date}</Date>
            {blurb.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
            <ReadMoreContainer href={link} target="_blank">
              <ReadMoreText>Listen Here</ReadMoreText>
              <ReadMoreArrow src={rightArrow} />
            </ReadMoreContainer>
          </ProjectBody>
        </ImgProjectContainer>
      )}
      {type === "youtube" && (
        <YoutubeProjectContainer className={className} id={bodyId}>
          <ReactPlayer
            width={isDesktop ? "450px" : "90%"}
            style={isDesktop ? {} : { margin: "auto" }}
            url={link}
          />
          <YoutubeProjectTitle>{title}</YoutubeProjectTitle>
        </YoutubeProjectContainer>
      )}
    </>
  );
}

ProjectUnit.propTypes = {
  type: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  blurb: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export default ProjectUnit;

// arrow link: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
