import styled from "styled-components";
import colours from "../common/colours";
import { Link } from "react-router-dom";
import mediaQueries from "../common/mediaQueries";
import Socials from "./Socials";

const Container = styled.div`
  background: ${colours.lightTeal};

  // height: 125px;
  width: 100%;

  display: inline-block;
  align-items: center;
  text-align: center;

  padding: 32px 0;

  font-weight: lighter;
`;

const FooterTitle = styled.h1`
  color: white;
  font-family: "Darker Grotesque";
  margin-bottom: 0;
`;

const FooterListItem = styled.li`
  margin: 12px 0;
  a {
    font-size: 20px;
    text-decoration: none;
    padding: 16px;
    color: white;
    @media screen and (min-width: ${mediaQueries.large}) {
      font-size: 24px;
    }
  }
`;

const FooterList = styled.ul`
  list-style-type: none;
  justify-content: center;
  padding 0;

  margin-top: 32px;

  @media screen and (min-width: ${mediaQueries.large}) {
    padding: 16px 16px 6px 16px;
    display: flex;
    overflow: hidden;
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SocialsContainer = styled.div`
  // background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px 0 48px 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: 24px;
  }
`;

const FooterLink = styled(Link)`
  opacity: 1;
  color: black;
  &:hover {
    opacity: 0.6;
  }
`;

function Footer() {
  return (
    <Container>
      <StyledLink to="/">
        <FooterTitle>Eric Elliot Lee</FooterTitle>
      </StyledLink>
      <FooterList>
        <FooterListItem>
          <FooterLink to="/about">About Me</FooterLink>
        </FooterListItem>
        <FooterListItem location="/coaching">
          <FooterLink to="/coaching">Vocal Coaching</FooterLink>
        </FooterListItem>
        <FooterListItem>
          <FooterLink to="/portfolio">Portfolio</FooterLink>
        </FooterListItem>
        <FooterListItem>
          <FooterLink to="/press">Press</FooterLink>
        </FooterListItem>
        <FooterListItem>
          <FooterLink to="/contact">Contact</FooterLink>
        </FooterListItem>
      </FooterList>
      <SocialsContainer>
        <Socials
          mobileSize="40"
          desktopSize="40"
          desktopWidth="20%"
          mobileWidth="40%"
        />
      </SocialsContainer>
    </Container>
  );
}

export default Footer;
