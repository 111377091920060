const colours = {
  lightPurple: "#AEAAC0",
  paleGreen: "#ACC0AA",
  lightTeal: "#87A3AD",
  brightMustard: "#FFC107",
  paleMustard: "#FFC163",
  lightGrey: "#AEAEAE",
  charcoalGrey: "#282829",
  paleRed: "#C0817D",
  paleBlue: "#a6fffc",
};

export default colours;
