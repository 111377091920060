import styled from "styled-components";
import { useEffect } from "react";
import useMediaLayout from "use-media";
import $ from "jquery";
import mediaQueries from "../../common/mediaQueries";
import { NavSpacer, PageContainer } from "../../common/Common";
import colours from "../../common/colours";
import ProjectUnit from "./ProjectUnit";
import sera from "../../../assets/seraphine.jpg";

const HeaderUnderline = styled.span`
  background: ${colours.paleMustard};
  height: 10px;
  position: absolute;
  bottom: 10%;
  z-index: -1;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 30%;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 90%;
  }

  animation: fadeInProject ease 1200ms;
  -webkit-animation: fadeInProject ease 1200ms;

  @keyframes fadeInProject {
    0% {
      width: 0%;
    }
    100% {
      @media screen and (min-width: ${mediaQueries.large}) {
        width: 30%;
      }
      @media screen and (max-width: ${mediaQueries.large}) {
        width: 90%;
      }
    }
  }
`;

const HeadingContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 32px;
  @media screen and (min-width: ${mediaQueries.large}) {
    // margin-left: 10%;
    margin-bottom: 0;
  }
`;

const Heading = styled.h1`
  color: black;
  margin-top: 0 !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: revert;
  }
`;

const SubHeading = styled.p``;

const Header = styled.div`
  padding: 16px 16px 0;
`;

const ProjectsContainer = styled.div`
  margin-bottom: 100px;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin: 24px 0 100px 10%;
  }
`;

const ProjectSectionsContainer = styled.div`
  margin-top: 32px;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: 75px;
    margin-right: 10%;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    hr {
      display: none;
    }
  }
`;
const ProjectSection = styled.div``;

const ProjectSectionHeading = styled.h2`
  font-family: "Alata", sans-serif !important;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  @media screen and (max-width: ${mediaQueries.large}) {
    margin-bottom: 16px;
    margin-top: 32px;
    text-align: center;
  }
`;

function Portfolio() {
  const isDesktop = useMediaLayout({ minWidth: mediaQueries.large });
  const sectionIds = ["theatre", "songs", "compositions"];
  useEffect(() => {
    if (isDesktop) {
      $(document).scroll(function () {
        var docScroll = $(document).scrollTop();
        sectionIds.forEach((id) => {
          var offset = $(`#${id}`).offset().top;
          var opacity;
          if (
            docScroll >= offset - 300 &&
            docScroll <= offset + $(`#${id}`).outerHeight() - 300
          ) {
            opacity = "1";
          } else {
            opacity = "0.5";
          }
          $(`#${id}`).stop().animate({ opacity: opacity }, 300);
        });
      });
    }
  });
  return (
    <PageContainer>
      <NavSpacer />
      <ProjectsContainer>
        <Header>
          <HeadingContainer>
            <Heading>Portfolio</Heading>
            <HeaderUnderline />
          </HeadingContainer>
          <SubHeading>
            Here’s a few of the things I’ve worked on in the past. See my
            contact page to get in touch!
          </SubHeading>
        </Header>
        <ProjectSectionsContainer>
          <ProjectSection id="theatre">
            <ProjectSectionHeading>THEATRE</ProjectSectionHeading>
            <hr />
            <ProjectUnit
              type={"img"}
              imageSrc={sera}
              title={"“SERAPHINE” – CO-WRITTEN WITH VIRGINIE DAIGLE"}
              date={"Released February 7th, 2020"}
              blurb={[
                "“Seraphine” was written and developed in the Canadian Musical Theatre Writers’ Collective’s 2018-2019 Montreal workshop led by the incomparable Jonathan Monro.",
                "The story follows Seraphine, a young vampire with a burning desire to win the heart of Dracula, the lord of all vampires. When she is confronted with the memory of the night she was transformed into a vampire, she must make a decision between getting what she loves, and reclaiming what she lost.",
              ]}
              link={
                "https://seraphinemusical.bandcamp.com/releases?fbclid=IwAR0wIi7X2F1_HpROs1BZMjlH_9rpbCmGjtR8NtL4Mba8L6DciTfGLjGCEBY"
              }
              index={1}
            />
          </ProjectSection>
          <ProjectSection id="songs">
            <ProjectSectionHeading>SONGS</ProjectSectionHeading>
            <hr />
            <ProjectUnit
              type={"youtube"}
              title={
                "“I WANT TO BE A WRITER FOR SESAME STREET” – ORIGINAL SONG (2021)"
              }
              link={
                "https://www.youtube.com/watch?v=cjGBTCzXTSc&ab_channel=EricElliotLee-Composer"
              }
              index={2}
            />
            <ProjectUnit
              type={"youtube"}
              title={"“MARINA” – ORIGINAL SONG (2021)"}
              link={
                "https://www.youtube.com/watch?v=uESvc7yKRrA&ab_channel=EricElliotLee-Composer"
              }
              index={3}
            />

            <ProjectUnit
              type={"youtube"}
              title={"“REAL RAIN” – LYRICS BY KENNY STEIN (2018)"}
              link={"https://www.youtube.com/watch?v=KSnzJGVr9pk"}
              index={4}
            />
          </ProjectSection>
          <ProjectSection id="compositions">
            <ProjectSectionHeading>COMPOSITIONS</ProjectSectionHeading>
            <hr />
            <ProjectUnit
              type={"youtube"}
              title={
                "“TOM THOMSON (A SYMPHONIC POEM BASED ON THE PAINTINGS OF TOM THOMSON)” (2020)"
              }
              link={
                "https://www.youtube.com/watch?v=RL15NBQU_eQ&feature=emb_title"
              }
              index={5}
            />

            <ProjectUnit
              type={"youtube"}
              title={"“EXTENDED ETUDE” (2020)"}
              link={
                "https://www.youtube.com/watch?v=EMCJLIWrR94&feature=emb_title"
              }
              index={6}
            />
          </ProjectSection>
        </ProjectSectionsContainer>
      </ProjectsContainer>
    </PageContainer>
  );
}

export default Portfolio;
