import styled from "styled-components";
import useMediaLayout from "use-media";
import mediaQueries from "../../common/mediaQueries";
import {
  NavSpacer,
  PageContainer,
  imageWithCaption,
} from "../../common/Common";
import colours from "../../common/colours";

import shadow from "../../../assets/shadow.jpg";

const HeaderUnderline = styled.span`
  background: ${colours.lightGrey};
  height: 10px;
  position: absolute;
  bottom: 10%;
  z-index: -1;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 30%;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 90%;
  }

  animation: fadeInContact ease 1200ms;
  -webkit-animation: fadeInContact ease 1200ms;

  @keyframes fadeInContact {
    0% {
      width: 0%;
    }
    100% {
      @media screen and (min-width: ${mediaQueries.large}) {
        width: 30%;
      }
      @media screen and (max-width: ${mediaQueries.large}) {
        width: 90%;
      }
    }
  }
`;

const HeadingContainer = styled.div`
  position: relative;
  display: flex;
  @media screen and (max-width: ${mediaQueries.large}) {
    place-content: center !important;
  }
`;

const Heading = styled.h1`
  color: black;
  margin-top: 0 !important;
  margin-bottom: 0;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin-top: revert;
  }
`;

const SubHeading = styled.p`
  @media screen and (max-width: ${mediaQueries.large}) {
    text-align: center !important;
    font-weight: bolder;
  }
`;

const Header = styled.div``;

const Container = styled.div`
  padding: 16px;
  @media screen and (min-width: ${mediaQueries.large}) {
    margin: 24px 0 0 10%;
  }
`;

const BodyContainer = styled.div`
  margin-top: 32px;
  @media screen and (min-width: ${mediaQueries.large}) {
    display: flex;
    padding: 0 0 250px;
    margin-top: 56px;
  }
`;

const ImageContainer = styled.div`
  @media screen and (min-width: ${mediaQueries.large}) {
    width: 50%;
  }
`;

const ImageContainerMobile = styled.div`
  margin-bottom: 64px;
`;

const ContactDetails = styled.div`
  padding: 16px;

  @media screen and (min-width: ${mediaQueries.large}) {
    width: 40%;
    margin-left: 48px;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    text-align: center !important;
    text-align-last: center !important;
  }
`;

const ContactLink = styled.a`
  opacity: 1;
  color: black;
  &:hover {
    opacity: 0.6;
  }
`;

const ContactText = styled.div``;

const Location = styled.p`
  color: ${colours.lightTeal};
  margin-bottom: 32px;
  text-align: center;
`;

function Contact() {
  const isDesktop = useMediaLayout({ minWidth: mediaQueries.large });
  return (
    <PageContainer>
      <NavSpacer />
      <Container>
        <Header>
          <HeadingContainer>
            <Heading>Contact</Heading>
            <HeaderUnderline />
          </HeadingContainer>
          <SubHeading>
            {`Want to get in touch? `}
            {`Looking for a collaborator? `}
            Drop me a line!
          </SubHeading>
        </Header>
        <BodyContainer>
          {isDesktop && (
            <ImageContainer>
              {imageWithCaption(
                shadow,
                "He was one of the most promising first-year students of his cohort",
                "Dr. Georges Dimitrov"
              )}
            </ImageContainer>
          )}
          <ContactDetails>
            <ContactText>
              <ContactLink href="mailto:ericelliotlee@gmail.com">
                ericelliotlee@gmail.com
              </ContactLink>
              <br />
              <ContactLink href="tel:+15142617405">514-261-7405</ContactLink>
            </ContactText>
            <Location>
              Montreal, QC
              <br />
              Canada
            </Location>
          </ContactDetails>
          {!isDesktop && (
            <ImageContainerMobile>
              {imageWithCaption(
                shadow,
                "He was one of the most promising first-year students of his cohort",
                "Dr. Georges Dimitrov"
              )}
            </ImageContainerMobile>
          )}
        </BodyContainer>
      </Container>
    </PageContainer>
  );
}

export default Contact;
